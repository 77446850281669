<template>
  <div>
    <div>
      <b-img
        center
        style="width: 100%"
        src="../../public/art-1-1.jpg"
        fluid
        alt="RTI and Algorithmic Systems"
      ></b-img>
    </div>
    <div style="padding: 1em">
      <h3>About the work</h3>
      <div style="display: flex; justify-content: center">
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/wIaY81JoqPc"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
    </div>

    <div style="padding: 1em" class="art-description">
      <p>
        This work imagines a near future where there is a people's government -
        that is, a government that works in the people's interests as a whole.
        It explores how this government would make decisions related to
        technology in one sector, agriculture. In doing so it points towards
        answers to two questions: (1) How can AI help in achieving the dream of
        a largely planned economy? and (2) What contradictions exist in such a
        society, and what role does technology play in resolving or furthering
        them? The work takes a dialectical view of technology and society. It
        uses the format of a Twitter feed, for familiarity and ease of
        understanding, and documents the goings-on in a national conference on
        agriculture and technology. It is accompanied by an essay for context.
      </p>
    </div>
    <div style="padding: 1em"></div>
    <div style="display: flex; justify-content: center">
      <blockquote class="twitter-tweet" data-theme="dark">
        <p lang="en" dir="ltr">
          Welcome to the live blog of the National Conference on Agriculture and
          Technology.<br />
          <a
            href="https://twitter.com/hashtag/NCAT2035?src=hash&amp;ref_src=twsrc%5Etfw"
            >#NCAT2035</a
          >
          <a href="https://t.co/Wxd1IshC7x">pic.twitter.com/Wxd1IshC7x</a>
        </p>
        &mdash; National Conference of Agriculture and Technology (@NCAT_2035)
        <a
          href="https://twitter.com/NCAT_2035/status/1363866582703742978?ref_src=twsrc%5Etfw"
          >February 22, 2021</a
        >
      </blockquote>
    </div>
    <div style="padding: 1em">
      <h3>About the Artists</h3>
    </div>
    <div style="padding: 1em" class="artist-name">
      <p>
        <b>All India IT and ITeS Employees’ Union (AIITEU)</b>: Is a registered
        union of tech workers across India and affiliated to the Centre of
        Indian Trade Unions. The contributors from AIITEU include:
      </p>
      <ul>
        <li>
          <b>Spandan Pratyush</b>, a machine learning engineer working in
          natural language processing, with prior experience in image processing
          and full stack software development.
        </li>
        <li>
          <b>Rikta Krishnaswamy</b>, a design researcher working at the
          intersection of people and appropriate technology.
        </li>
        <li>
          <b>Jai Vipra</b>, a technology policy researcher focusing on the use
          of emerging technology in the Global South.
        </li>
      </ul>

      <b>Critters Collective</b> : Is a group of cross-disciplinary designers
      that speculate, critique, ruminate & Indian design futures. Here are brief
      descriptions of the work, interests and expertise of the artists and
      designers who would be working on this piece:
      <ul>
        <li>
          <b>Madhu Priyanka Kannabiran</b>, a design researcher with training in
          industrial design and a keen interest in discursive design. She is
          delving into behavioural science to complement her qualitative
          research practice. Her work primarily revolves around Public Health in
          India. Madhu has also recently begun her journey as an educator,
          teaching at the National Institute of Design as a visiting faculty
          member.
        </li>
        <li>
          <b>Aarushi Bapna</b>, a critical thinker, researcher and visual
          designer. She is interested in leveraging design for good and
          understanding the socio-cultural implications of emerging tech.
        </li>
        <li>
          <b>Harshali Paralikar</b>, a designer, researcher, and strategist
          working in the intersection of Behaviour Science, Technology, and
          Design.
        </li>
        <li>
          <b>Yatharth</b> is a creative coder and new media artist interested in
          the accessibility of technology, how media impacts us & emerging
          collaborations of man-machine.
        </li>
        <li>
          <b>Prajjwal Chandra</b>, a visual artist and digital creator with
          varied interests in narrative and storytelling. He also delves into
          current trends, social media and popular culture phenomena.
        </li>
        <li>
          <b>Ajitesh Lokhande</b>, a visual communication designer who loves
          working on brand identities, typography and graphic design while
          alternating between the lenses of behavioural economics, culture
          jamming and DIY ideology.
        </li>
      </ul>
      <div style="padding: 1em" class="art-link">
        <p>
          Twitter for
          <a target="_blank" href="https://twitter.com/NCAT_2035"
            >National Conference of Agriculture and Technology</a
          >
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    let twitterScript = document.createElement("script");
    twitterScript.setAttribute(
      "src",
      "https://platform.twitter.com/widgets.js"
    );
    document.head.appendChild(twitterScript);
  },
};
</script>
